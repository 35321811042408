import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  
  isText:boolean=false;

  @Input() pic_path: String;
  @Input() text: String;
  @Input() pic_path_dark: String;
  @Input() mail: String;
  @Input() contact: String;
  isIE : boolean;

  
  constructor() { 
    
  }


  focus(){

    this.isText=true;
    var elmnt = elmnt.scrollIntoView(true);
    document.getElementById("focus").scrollTop += 10;
    

    
    console.log('focus is running');
  }
  ngOnInit() {
    this.isIE = this.getBrowserName();
    console.log(this.isIE);
  }
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return true;
        case agent.indexOf('trident') > -1:
        return true;
      default:
        return false;
    }
}


}

import { Component } from '@angular/core';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tema';

  Marielle_text = `Kreativ, nyfiken och snabb på bollen. 
  Äter gärna helgrillad kyckling. Bor i staden med alla björkarna. 
  10 år som tillväxtrådgivare. Drivs av att utveckla människor. Drivmedel: kaffe & glass.`
  Marielle_contact = 'Marielle Lindberg 070-371 71 03';
  Marielle_mail = 'marielle@temautveckling.com';
  Marielle = '../assets/img/Marielle_square.png';
  Marielle_dark = '../assets/img/dark-circle.png';

  Tobias_text = `Tanke före handling. Humoristisk och grym på text. 
  Många chilinötter blir det. Och många mil i skidspåret.
  20 år på nacken inom analys och affär. Drivs av att finna vägar som gynnar andra.
  Drivmedel: kaka till kaffet. Och kaffe.`;
  Tobias_contact = 'Tobias Ekevind 070-831 11 79'; 
  Tobias_mail = 'tobias@temautveckling.com';
  Tobias = '../assets/img/Tobias_square.png';
  Tobias_dark = '../assets/img/dark-circle.png';
 

  

  ngInit(){}
}
